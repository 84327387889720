<template>
  <div id="champion-rank">
    <!-- 赛季选择框 -->
    <el-select
        v-model="selectedSeason"
        placeholder="选择赛季"
        style="margin-bottom: 20px; width: 300px;"
        @change="fetchChampionData"
    >
      <el-option
          v-for="season in seasons"
          :key="season.season_id"
          :label="season.season_name"
          :value="season.season_id"
      />
    </el-select>
    <el-input
        v-model="searchQuery"
        placeholder="输入英雄名称搜索"
        style="margin-bottom: 20px; width: 300px;"
        clearable
    />
    <el-table
        :data="filteredData"
        stripe
        style="width: 100%">
      <el-table-column
          label="英雄名称"
          width="200"
      >
        <template slot-scope="scope">
          <img :src="scope.row.champion_icon" alt="Champion Icon" style="width: 20px; height: 20px; margin-right: 5px;">
          {{ scope.row.champion_name }}
        </template>
      </el-table-column>
      <el-table-column
          prop="match_count"
          label="比赛场次"
          width="120"
          sortable
      />
      <el-table-column
          prop="win_count"
          label="胜场"
          width="100"
          sortable
      />
      <el-table-column
          prop="win_percent"
          label="胜率"
          width="120"
          sortable
      >
        <template slot-scope="scope">
          {{ scope.row.win_percent }}%
        </template>
      </el-table-column>
      <el-table-column
          prop="ban_count"
          label="Ban数"
          width="100"
          sortable
      />
      <el-table-column
          prop="ban_percent"
          label="Ban率"
          width="100"
          sortable
      >
        <template slot-scope="scope">
          {{ scope.row.ban_percent }}%
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ChampionRankPage',
  data() {
    return {
      searchQuery: '', // 搜索框输入
      championData: [], // 英雄数据
      loading: false, // 加载状态
      error: null, // 错误信息
      sortKey: '', // 当前排序字段
      sortOrder: '', // 当前排序顺序
      seasons: [], // 存储赛季选项
      selectedSeason: null, // 选中的赛季ID
    };
  },
  computed: {
    filteredData() {
      // 根据搜索框的输入进行过滤
      const filteredChampions = this.championData.filter(champion =>
          champion.champion_name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );

      // 排序逻辑
      return filteredChampions.sort((a, b) => {
        let winPercentDiff = b.win_percent - a.win_percent; // 先按胜率排序
        if (winPercentDiff !== 0) {
          return winPercentDiff; // 如果胜率不同，直接返回比较结果
        }
        // 如果胜率相同，再按胜场排序
        return b.win_count - a.win_count;
      });
    }
  },
  created() {
    this.fetchSeasons();
  },
  methods: {
    async fetchSeasons() {
      try {
        const response = await axios.get('/api/lol/v1/panel/seasons'); // 获取赛季数据的接口
        this.seasons.push({ season_id: '', season_name: '总排行榜' }); // 添加一个总排行榜选项
        this.seasons.push(...response.data.season_list); // 将获取到的赛季数据添加到选项中
        if (this.seasons.length > 0) {
          this.selectedSeason = this.seasons[0].season_id; // 默认选择第一个赛季
          this.fetchChampionData(); // 加载初始赛季的数据
        }
      } catch (err) {
        this.error = err.response?.data?.error_msg || '无法加载赛季数据。';
      }
    },
    async fetchChampionData() {
      this.loading = true;
      try {
        const response = await axios.get('/api/lol/v1/panel/champion_rank?season_id=' + this.selectedSeason); // 请根据实际接口修改 URL
        this.championData = response.data.rank_list; // 根据返回的数据结构调整
      } catch (err) {
        this.error = err.response?.data?.error_msg || '发生未知错误。';
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
#champion-rank {
  margin: 20px;
}

.el-table th, .el-table td {
  text-align: center;
  font-weight: bold;
}

.el-table img {
  border-radius: 8px;
}
</style>
